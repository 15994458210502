export const COLORS = {
  WHITE: {
    900: "#FFFFFF",
    800: "#F6F6F7",
    700: "#F0F0F1",
    600: "#EEEEEF",
    500: "#DDDDE3",
    300: "#CCCCCD",
  },
  BLACK: {
    900: "#667",
    500: "#222",
    400: "#1F1F1F",
    300: "#1A1A1A",
    100: "#111",
  },
  BLUE: {
    500: "#2626d6",
    400: "#222298",
    300: "#21213E",
    100: "#0E0E1D",
  },
}

export const HREFS = {
  MAIL_TO: "mailto:contact@foilthepolice.org",
  README: "https://github.com/foilthepolice/foilthepolice.org#foilthepoliceorg",
}

export const STATES = {
  al: "Alabama",
  ak: "Alaska",
  az: "Arizona",
  ar: "Arkansas",
  ca: "California",
  co: "Colorado",
  ct: "Connecticut",
  de: "Delaware",
  dc: "District Of Columbia",
  fl: "Florida",
  ga: "Georgia",
  hi: "Hawaii",
  id: "Idaho",
  il: "Illinois",
  in: "Indiana",
  ia: "Iowa",
  ks: "Kansas",
  ky: "Kentucky",
  la: "Louisiana",
  me: "Maine",
  md: "Maryland",
  ma: "Massachusetts",
  mi: "Michigan",
  mn: "Minnesota",
  ms: "Mississippi",
  mo: "Missouri",
  mt: "Montana",
  ne: "Nebraska",
  nv: "Nevada",
  nh: "New Hampshire",
  nj: "New Jersey",
  nm: "New Mexico",
  ny: "New York",
  nc: "North Carolina",
  nd: "North Dakota",
  oh: "Ohio",
  ok: "Oklahoma",
  or: "Oregon",
  pa: "Pennsylvania",
  ri: "Rhode Island",
  sc: "South Carolina",
  sd: "South Dakota",
  tn: "Tennessee",
  tx: "Texas",
  ut: "Utah",
  vt: "Vermont",
  va: "Virginia",
  wa: "Washington",
  wv: "West Virginia",
  wi: "Wisconsin",
  wy: "Wyoming",
}
